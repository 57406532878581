import { FC } from 'react';
import { Box, Container, Grid, Hidden, makeStyles, Theme } from '@material-ui/core';
import { SquareIconComponent } from 'components/atoms';
import { RedCirclePointedIcon } from 'svgs';
import clsx from 'clsx';
import { ContentDescription } from 'components/structures/general';

interface GetInTouchProps {
  title: string;
  text: string;
  link: { text: string; href: string };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(10, 0, 20),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(15, 0, 30),
    },
  },
  text: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(7),
    },
  },
  container: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  squarePosition: {
    position: 'absolute',
    left: 15,
    bottom: -20,
  },
  squareSecondPosition: {
    left: 60,
    bottom: -65,
  },
  redCirclePosition: {
    position: 'absolute',
    top: 0,
    right: 0,
    [theme.breakpoints.up('lg')]: {
      right: 100,
    },
  },
  link: {
    marginTop: 0,
  },
}));

const GetInTouch: FC<GetInTouchProps> = ({ title, text, link }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth={false} fixed>
        <Grid container className={classes.container} style={{ position: 'relative' }}>
          <Grid lg={5} md={6} xs={12} item>
            <ContentDescription
              title={{ text: title }}
              text={{ text: text, className: classes.text }}
              link={{ href: link.href, text: link.text, className: classes.link }}
            />
          </Grid>
          <Hidden smDown>
            <SquareIconComponent className={classes.squarePosition} />
            <SquareIconComponent className={clsx(classes.squarePosition, classes.squareSecondPosition)} />
            <RedCirclePointedIcon className={classes.redCirclePosition} />
          </Hidden>
        </Grid>
      </Container>
    </Box>
  );
};

export default GetInTouch;
