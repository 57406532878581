import { alpha, Box, Container, makeStyles, Theme, Typography } from '@material-ui/core';
import { allPartners, arrowBtn } from 'images/main/partners';
import React, { FC } from 'react';
import Carousel from 'react-multi-carousel';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(10, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(15, 0),
    },
  },
  logoBox: {
    minWidth: 210,
    minHeight: 100,
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.info.light,
    [theme.breakpoints.up('md')]: {
      minWidth: 400,
      minHeight: 120,
    },
  },
  partnerImage: {
    width: '100%',
    maxHeight: 40,
    maxWidth: 190,
    objectFit: 'contain',
    userDrag: 'none',
    userSelect: 'none',
    [theme.breakpoints.up('md')]: {
      maxHeight: 60,
      maxWidth: 300,
    },
  },
  title: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(7),
    },
  },
  linkButton: {
    borderRadius: theme.spacing(0, 4, 4, 0),
    height: '100%',
    width: theme.spacing(8),
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'box-shadow 300ms',
    '&:hover': {
      boxShadow: `0px 0px 10px 3px ${alpha(theme.palette.primary.main, 0.4)}`,
    },
  },
}));

const responsive = {
  mobile: {
    breakpoint: {
      max: 960,
      min: 0,
    },
    items: 1,
  },
  tablet: {
    breakpoint: {
      max: 1280,
      min: 960,
    },
    items: 2,
  },
  desktop: {
    breakpoint: {
      max: 5000,
      min: 1280,
    },
    items: 3,
  },
};

const PartnersPrompter: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <Container>
        <Typography className={classes.title} variant="h2">
          Our Partners
        </Typography>
      </Container>
      <Carousel ssr infinite autoPlay responsive={responsive} arrows={false} autoPlaySpeed={2000} draggable>
        {allPartners.map((props, index) => (
          <CarouselCard key={index} {...props} />
        ))}
      </Carousel>
    </Box>
  );
};

interface CarouselCardProps {
  image: string;
  link: string;
}

const CarouselCard: FC<CarouselCardProps> = ({ image, link }) => {
  const classes = useStyles();
  return (
    <Box className={classes.logoBox} m={3} mb={10} position="relative" display="flex" justifyContent="center" alignItems="center">
      <Box>
        <img className={classes.partnerImage} src={image} alt={link} />
      </Box>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Box className={classes.linkButton}>
          <img src={arrowBtn} alt="arrow" />
        </Box>
      </a>
    </Box>
  );
};

export default PartnersPrompter;
