import { FC } from 'react';
import { Box, Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { CountUp } from 'components/atoms';

interface Statistic {
  numberValue: number;
  text: string;
  prefix?: string;
  suffix?: string;
}

interface StatisticsProps {
  statistics: Array<Statistic>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(10, 0),
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    margin: theme.spacing(10, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(15, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(18, 0),
    },
  },
  counter: {
    fontSize: 48,
    fontWeight: 700,
    marginRight: theme.spacing(3),
    lineHeight: 1.24,
  },
  text: {
    alignSelf: 'center',
    maxWidth: 87,
    minWidth: 'min-content',
  },
  counterWrapper: {
    display: 'flex',
    justifyContent: 'center',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      '&:not(:last-child)': {
        marginBottom: 0,
      },
    },
  },
}));
const StatisticsSection: FC<StatisticsProps> = ({ statistics }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Container maxWidth={false} fixed>
        <Grid container justifyContent="center">
          <Grid lg={10} md={12} item>
            <Grid container justifyContent="space-between">
              {statistics.map(({ numberValue, text, prefix, suffix }, index) => (
                <Grid xs={12} md="auto" className={classes.counterWrapper} item key={index}>
                  <CountUp className={classes.counter} end={numberValue} suffix={suffix} prefix={prefix} separator=" " />
                  <Typography className={classes.text} variant="body2" component="span">
                    {text}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default StatisticsSection;
