import { Box, Container, Grid, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { UpperTitle } from 'components/atoms';
import { Arrows } from 'components/structures/main/Slide';
import { FC, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import Link from 'next/link';
import 'react-multi-carousel/lib/styles.css';

interface SlideProps {
  title: { colorLeft: string; colorRight: string; text: string };
  image: { image: string; srcSet: string };
  link: string;
}

interface SliderProps {
  sliderList: Array<SlideProps>;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    section: {
      padding: theme.spacing(10, 0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(15, 0),
      },
    },
    link: {
      color: theme.palette.switchedColors.green,
      fontSize: 16,
      [theme.breakpoints.up('sm')]: {
        fontSize: 20,
      },
    },
    boxWrapper: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 3),
      },
    },
    titleWrapper: {
      marginBottom: theme.spacing(6),
    },
    arrowsSection: {
      marginBottom: theme.spacing(6),
      marginTop: 0,
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(10),
      },
    },
    boxItem: {
      borderRadius: 20,
      backgroundColor: theme.palette.info.light,
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      userSelect: 'none',
      overflow: 'hidden',
      cursor: 'pointer',
      '& img': {
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'right bottom',
      },
    },
    text: {
      // WebkitBackgroundClip: 'text',
      // WebkitTextFillColor: 'transparent',
      padding: theme.spacing(5, 3, 0),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6, 9, 0),
      },
    },
  });
});

const Slider: FC<SliderProps> = ({ sliderList }) => {
  const ref = useRef(null);
  const [slide, setSlide] = useState(0);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const prev = () => {
    if (!loading) {
      ref.current.previous();
      if (slide === 0) {
        setSlide(sliderList.length - 1);
      } else {
        setSlide(slide - 1);
      }
    }
  };

  const next = () => {
    if (!loading) {
      ref.current.next();
      if (slide >= sliderList.length - 1) {
        setSlide(0);
      } else {
        setSlide(slide + 1);
      }
    }
  };

  return (
    <>
      {!!sliderList.length && (
        <Box className={classes.section}>
          <Container maxWidth={false} fixed>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={10} className={classes.titleWrapper}>
                <Grid container justifyContent="space-between" alignItems="flex-end">
                  <Grid item md="auto">
                    <UpperTitle text="Latest Achievements" />
                    <Typography variant="h2">Our Projects</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={10}>
                {sliderList.length > 1 && (
                  <Arrows
                    className={classes.arrowsSection}
                    totalSlides={Array.from(sliderList.keys())}
                    currentSlide={slide}
                    next={next}
                    prev={prev}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Carousel
                  ref={ref}
                  responsive={{
                    mobile: {
                      breakpoint: {
                        max: 960,
                        min: 0,
                      },
                      items: 1,
                    },
                    tablet: {
                      breakpoint: {
                        max: 5000,
                        min: 960,
                      },
                      items: 2,
                    },
                  }}
                  beforeChange={() => setLoading(true)}
                  afterChange={() => setLoading(false)}
                  additionalTransfrom={0}
                  minimumTouchDrag={80}
                  arrows={false}
                  swipeable={false}
                  ssr={true}
                  draggable={false}
                  keyBoardControl={false}
                  slidesToSlide={1}
                  infinite
                  itemClass={classes.boxWrapper}
                >
                  {sliderList.map(({ title, image, link }, index) => (
                    <Link key={'image-' + index} href={link}>
                      <a>
                        <Box className={classes.boxItem}>
                          <Typography
                            variant="h3"
                            className={classes.text}
                            style={{
                              background: `linear-gradient(to right, ${title.colorLeft} 0%, ${title.colorRight} 100%)`,
                              WebkitBackgroundClip: 'text',
                              backgroundClip: 'text',
                              color: 'transparent',
                            }}
                          >
                            {title.text}
                          </Typography>
                          <img src={image.image} alt={image.image} srcSet={image.srcSet} />
                        </Box>
                      </a>
                    </Link>
                  ))}
                </Carousel>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Slider;
