import { Card, Collapse, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FC, ReactNode, useState } from 'react';

interface CardProps {
  title: string;
  text: string;
  button?: string;
  image?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(5),
    width: '100%',
    minHeight: '100%',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      padding: theme.spacing(6),
    },
  },
  image: {
    height: 39,
    marginBottom: theme.spacing(1),
  },
  title: {
    lineHeight: 1.4,
    color: theme.palette.common.white,
  },
  text: {
    lineHeight: 1.4,
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: 24,
    lineHeight: 1.3,
    [theme.breakpoints.up('sm')]: {
      marginTop: 21,
    },
  },
}));

const CardSimple: FC<CardProps> = ({ title, text, button, image }) => {
  const classes = useStyles();
  const [isHovered, setIsHovered] = useState(false);
  const handleHover = () => setIsHovered(true);
  const handleUnHover = () => setIsHovered(false);

  return (
    <Card className={classes.root} onMouseEnter={handleHover} onMouseLeave={handleUnHover}>
      <Collapse in={!isHovered}>
        <Grid className={classes.image} item>
          {image}
        </Grid>
      </Collapse>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <Typography variant="body2" className={classes.text}>
        {text}
      </Typography>
      <Collapse in={isHovered}>
        <Typography variant="body2" className={classes.button}>
          {button}
        </Typography>
      </Collapse>
    </Card>
  );
};

export default CardSimple;
