import { FC } from 'react';
import { Button, Hidden, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useBrowserDetect } from 'hooks';

interface ArrowsProps extends IDefaultArrowsProps {
  className?: string;
  labels?: string[];
  onClickIndicator?: (slide: number) => void;
  prev?: () => void;
  next?: () => void;
}

export interface IDefaultArrowsProps {
  currentSlide: number;
  totalSlides: Array<number>;
  onClickIndicator?: (slide: number) => void;
}

interface StylesProps extends IDefaultArrowsProps {
  labels: boolean;
  isSafari: boolean;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    arrows: {
      display: 'flex',
      alignItems: ({ labels }: StylesProps) => (labels ? 'flex-start' : 'center'),
      justifyContent: 'space-between',
      marginTop: theme.spacing(16),
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
      },
    },
    arrow: {
      fontSize: 18,
      fontWeight: 400,
      padding: theme.spacing(2, 4),
      borderRadius: theme.spacing(1),
      lineHeight: 1,
      color: theme.palette.text.secondary,
      border: 0,
      boxShadow: 'none',
      outline: 'none',
      textTransform: 'uppercase',
      transition: 'color 300ms',
      marginTop: ({ labels }: StylesProps) => (labels ? theme.spacing(-2) : 'auto'),
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.info.main,
      },
    },
    indicatorsWrapper: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
      margin: theme.spacing(0, 3),
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0, 6),
      },
    },
    activeLine: {
      transition: 'left .6s ease-out',
      position: 'absolute',
      height: 3,
      top: 0,
      backgroundColor: theme.palette.switchedColors.main,
      marginTop: ({ isSafari }: StylesProps) => isSafari && 2,
      width: ({ totalSlides }: StylesProps) => `${(1 / totalSlides.length) * 100}%`,
      left: ({ currentSlide, totalSlides }: StylesProps) => `${(currentSlide / totalSlides.length) * 100}%`,
      [theme.breakpoints.up('sm')]: {
        height: 4,
      },
    },
    indicator: {
      width: '100%',
      border: 0,
      height: 3,
      padding: 0,
      outline: 'none',
      backgroundColor: theme.palette.secondary.light,
      [theme.breakpoints.up('sm')]: {
        height: 4,
        '&:hover': {
          cursor: ({ onClickIndicator }: StylesProps) => `${onClickIndicator ? 'pointer' : 'default'}`,
        },
      },
    },
    buttonSlide: {
      width: '100%',
      padding: 0,
      border: 0,
      background: 'transparent',
      userSelect: 'none',
      cursor: 'pointer',
      transition: 'color 300ms',
      '&:hover': {
        color: theme.palette.info.main,
      },
    },
    labelSlide: {
      marginTop: theme.spacing(3),
    },
  });
});

const Arrows: FC<ArrowsProps> = ({ className, totalSlides, currentSlide, labels = [], onClickIndicator, prev, next }) => {
  const isSafari = useBrowserDetect('safari');
  const classes = useStyles({ currentSlide, totalSlides, onClickIndicator, labels: !!labels.length, isSafari });

  const nextFunc = () => {
    if (currentSlide >= totalSlides.length - 1) {
      onClickIndicator(0);
    } else {
      onClickIndicator(currentSlide + 1);
    }
  };

  const prevFunc = () => {
    if (currentSlide < 1) {
      onClickIndicator(totalSlides.length - 1);
    } else {
      onClickIndicator(currentSlide - 1);
    }
  };

  return (
    <div className={clsx(classes.arrows, className)}>
      <Button color="primary" className={classes.arrow} onClick={prev ? prev : prevFunc} aria-label="Prev">
        Prev
      </Button>
      <div className={classes.indicatorsWrapper}>
        <div className={classes.activeLine} />
        {totalSlides.map((item, index) => (
          <button className={classes.buttonSlide} key={`indicator-${item}`} onClick={() => onClickIndicator?.(index)}>
            <div className={classes.indicator} />
            {labels[index] && (
              <Hidden smDown>
                <Typography
                  align="center"
                  className={classes.labelSlide}
                  color={currentSlide === index ? 'primary' : 'textPrimary'}
                  variant="subtitle2"
                >
                  {labels[index]}
                </Typography>
              </Hidden>
            )}
          </button>
        ))}
      </div>
      <Button color="primary" className={classes.arrow} onClick={next ? next : nextFunc} aria-label="Next">
        Next
      </Button>
    </div>
  );
};
export default Arrows;
