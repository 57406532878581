import { Box, Container, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { UpperSubTitle } from 'components/atoms';
import { CarouselComponent } from 'components/structures/general';
import { Arrows } from 'components/structures/main/Slide/index';
import { FC, ReactNode, useRef, useState } from 'react';

interface SliderInterface {
  svgIcon: ReactNode;
  title: string;
  text: string;
}

interface MultiSliderSectionProps {
  subTitle?: string;
  title: string;
  text?: string;
  sliderList: Array<SliderInterface>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(10),
    padding: theme.spacing(10, 0),
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(15),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(24, 0),
    },
  },
  subTitle: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(6),
    },
  },
  text: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(12),
    },
  },
  arrowsWrapper: {
    marginTop: 0,
  },
  sliderWrapper: {
    margin: theme.spacing(10, -3, 0),
    [theme.breakpoints.up('lg')]: {
      margin: 0,
    },
  },
  sliderItem: {
    minHeight: '100%',
    userSelect: 'none',
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  insideSliderItem: {
    backgroundColor: theme.palette.primary['600'],
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(6),
    borderRadius: theme.spacing(0, 4),
    color: theme.palette.common.white,
  },
  slideTitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  imageIcon: {
    maxWidth: 39,
    maxHeight: 39,
    marginBottom: theme.spacing(2),
  },
}));
const MultiSliderSection: FC<MultiSliderSectionProps> = ({ subTitle, title, text, sliderList }) => {
  const ref = useRef(null);
  const classes = useStyles();
  const [slide, setSlide] = useState(0);
  const [loading, setLoading] = useState(false);

  const prev = () => {
    if (!loading) {
      ref.current.previous();
      if (slide === 0) {
        setSlide(sliderList.length - 1);
      } else {
        setSlide(slide - 1);
      }
    }
  };

  const next = () => {
    if (!loading) {
      ref.current.next();
      if (slide >= sliderList.length - 1) {
        setSlide(0);
      } else {
        setSlide(slide + 1);
      }
    }
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth={false} fixed>
        <Grid container justifyContent="center" alignItems="center">
          <Grid lg={4} xs={12} item>
            {!!subTitle && <UpperSubTitle className={classes.subTitle} text={subTitle} />}
            <Typography variant="h2" component="h4" className={classes.title} color="inherit">
              {title}
            </Typography>
            {!!text && (
              <Typography variant="body2" component="h5" className={classes.text}>
                {text}
              </Typography>
            )}
            {sliderList.length > 1 && (
              <Arrows
                className={classes.arrowsWrapper}
                totalSlides={Array.from(sliderList.keys())}
                currentSlide={slide}
                next={next}
                prev={prev}
              />
            )}
          </Grid>
          <Grid lg={8} xs={12} item>
            <CarouselComponent
              ref={ref}
              className={classes.sliderWrapper}
              beforeChange={() => setLoading(true)}
              afterChange={() => setLoading(false)}
            >
              {sliderList.map(({ svgIcon, title, text }, index) => (
                <Box className={classes.sliderItem} key={index} display="flex">
                  <Box className={classes.insideSliderItem}>
                    <Box className={classes.imageIcon}>{svgIcon}</Box>
                    <Typography className={classes.slideTitle} variant="subtitle1" component="h6" color="inherit">
                      {title}
                    </Typography>
                    <Typography variant="body2" color="inherit">
                      {text}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </CarouselComponent>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default MultiSliderSection;
