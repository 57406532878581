import { FC } from 'react';
import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import CardSimple from 'components/atoms/CardSimple';
import { ArrowRight, MainIcon1, MainIcon2, MainIcon3, MainIcon4 } from 'svgs';
import { useMediaUp } from 'hooks';
import Link from 'next/link';
import { UpperSubTitle } from 'components/atoms';

interface OurApproachProps {
  title: string;
  subTitle: string;
  button: string;
  listItems?: string[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(10, 3, 10),
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.dark,
    marginBottom: theme.spacing(5),
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(15),
      padding: theme.spacing(17, 3),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(17, 39),
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(17, 74),
    },
  },
  title: {
    lineHeight: 1.3,
    marginBottom: theme.spacing(8),
    width: 'auto',
    color: theme.palette.common.white,
    fontSize: 32,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 255,
      fontSize: 48,
    },
  },
  buttonIcon: {
    marginLeft: theme.spacing(2),
  },
  button: {
    color: theme.palette.common.white,
    fontSize: 14,
    borderColor: theme.palette.primary.light,
    padding: theme.spacing(3, 7),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
      padding: theme.spacing(3, 7),
    },
  },
  cardContainer: {
    maxWidth: '100%',
    margin: 0,
  },
  gridItem: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.up('sm')]: {
      '&:not(:last-child)': {
        marginBottom: 0,
      },
    },
  },
}));

const OurApproach: FC<OurApproachProps> = ({ title, subTitle, button }) => {
  const classes = useStyles();
  const isSmallUp = useMediaUp('sm');

  return (
    <Grid className={classes.root} spacing={isSmallUp ? 6 : 0} container>
      <Grid xs={12} md={3} item>
        <UpperSubTitle text={subTitle} spacing />
        <Typography variant={'h2'} className={classes.title}>
          {title}
        </Typography>
        {/* <Link href="/">
          <a>
            <Button variant="outlined" className={classes.button}>
              {button} <ArrowRight className={classes.buttonIcon} />
            </Button>
          </a>
        </Link> */}
      </Grid>
      <Grid xs={12} md={9} item>
        <Grid container className={classes.cardContainer} justifyContent={'space-between'} spacing={isSmallUp ? 6 : 0}>
          <Grid className={classes.gridItem} xs={12} sm={6} item>
            <Link href="/consultancy">
              <a>
                <CardSimple
                  title={'Intuitive'}
                  text={'All our products are designed with the users’ interests in mind'}
                  image={<MainIcon1 />}
                  button={'Learn More'}
                />
              </a>
            </Link>
          </Grid>

          <Grid className={classes.gridItem} xs={12} sm={6} item>
            <Link href="/agile-prototyping">
              <a>
                <CardSimple
                  title={'Practical'}
                  text={'We deliver efficient, highly versatile software solutions'}
                  image={<MainIcon2 />}
                  button={'Learn More'}
                />
              </a>
            </Link>
          </Grid>

          <Grid className={classes.gridItem} xs={12} sm={6} item>
            <Link href="/bespoke-software">
              <a>
                <CardSimple
                  title={'Innovative'}
                  text={'The techniques we apply are cutting-edge and groundbreaking'}
                  image={<MainIcon3 />}
                  button={'Learn More'}
                />
              </a>
            </Link>
          </Grid>

          <Grid className={classes.gridItem} xs={12} sm={6} item>
            <Link href="/branding-design">
              <a>
                <CardSimple
                  title={'Inventive'}
                  text={'Our methods are creative and exceptionally adaptable'}
                  image={<MainIcon4 />}
                  button={'Learn More'}
                />
              </a>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OurApproach;
