export const slide1 = require('./slide1.jpg?webp');
export const slide2 = require('./slide2.jpg?webp');
export const slide3 = require('./slide3.jpg?webp');
export const slide4 = require('./slide4.jpg?webp');
export const slide5 = require('./slide5.jpg?webp');
export const bgAboutUs = require('./bg-about-us.png?webp');
export const image1AboutUs = require('./image1AboutUs.jpg?webp');
export const image1AboutUs2x = require('./image1AboutUs-2x.jpg?webp');
export const bgVideoBlock = require('./bg-video-block.jpg?webp');
export const mapBg = require('./map-bg.png?webp');
export const chisinau = require('./cities/chisinau.jpg?webp');
export const bucuresti = require('./cities/bucuresti.jpg?webp');
export const chicago = require('./cities/chicago.jpg?webp');
export const philadelphia = require('./cities/philadelphia.jpg?webp');
export const newJersey = require('./cities/new-jersey.jpg?webp');
