export { default as arrowBtn } from './arrow.svg';
const partner1 = require('./1.png?webp');
const partner2 = require('./2.png?webp');
const partner3 = require('./3.png?webp');
const partner4 = require('./4.png?webp');
const partner5 = require('./5.png?webp');
const partner6 = require('./6.png?webp');
const partner7 = require('./7.png?webp');
const partner8 = require('./8.png?webp');
const partner9 = require('./9.png?webp');
const partner10 = require('./10.png?webp');
const partner11 = require('./11.png?webp');
const partner12 = require('./12.png?webp');
const partner13 = require('./13.png?webp');
const partner14 = require('./14.png?webp');
const partner15 = require('./15.png?webp');
const partner16 = require('./16.png?webp');
const partner17 = require('./17.png?webp');
const partner18 = require('./18.png?webp');
const partner19 = require('./19.png?webp');
const partner20 = require('./20.png?webp');
const partner21 = require('./21.png?webp');
const partner22 = require('./22.png?webp');
const partner23 = require('./23.png?webp');
const partner24 = require('./24.png?webp');
const partner25 = require('./25.png?webp');
const partner26 = require('./26.png?webp');
const partner27 = require('./27.png?webp');
const partner28 = require('./28.png?webp');

type TPartner = {
  image: string;
  link: string;
};

export const allPartners: TPartner[] = [
  {
    image: partner1,
    link: 'https://www.apge.com',
  },
  {
    image: partner2,
    link: 'https://aggressiveenergy.com',
  },
  {
    image: partner3,
    link: 'https://www.dynegy.com',
  },
  {
    image: partner4,
    link: 'https://www.yepenergy.com',
  },
  {
    image: partner5,
    link: 'https://www.constellation.com',
  },
  {
    image: partner6,
    link: 'https://majorenergy.com',
  },
  {
    image: partner7,
    link: 'https://www.hudsonenergy.net',
  },
  {
    image: partner8,
    link: 'https://mecny.com',
  },
  {
    image: partner9,
    link: 'https://www.indraenergy.com',
  },
  {
    image: partner10,
    link: 'https://santannaenergyservices.com',
  },
  {
    image: partner11,
    link: 'https://www.aepenergy.com',
  },
  {
    image: partner12,
    link: 'https://www.entrustenergy.com',
  },
  {
    image: partner13,
    link: 'https://www.firstpointpower.com',
  },
  {
    image: partner14,
    link: 'https://www.cpg-energy.com',
  },
  {
    image: partner15,
    link: 'https://www.spragueenergy.com',
  },
  {
    image: partner16,
    link: 'https://www.megaenergyllc.com',
  },
  {
    image: partner17,
    link: 'https://www.plymouthenergy.com',
  },
  {
    image: partner18,
    link: 'https://www.nordicenergy-us.com',
  },
  {
    image: partner19,
    link: 'https://freepointsolutions.com',
  },
  {
    image: partner20,
    link: 'http://www.snyderbrothersinc.com',
  },
  {
    image: partner21,
    link: 'https://www.talenenergy.com',
  },
  {
    image: partner22,
    link: 'https://smartestenergy-us.azurewebsites.net/en_us/',
  },
  {
    image: partner23,
    link: 'https://www.starionenergy.com',
  },
  {
    image: partner24,
    link: 'https://www.cima-energy.com',
  },
  {
    image: partner25,
    link: 'https://www.sfeenergy.com',
  },
  {
    image: partner26,
    link: 'https://www.txu.com',
  },
  {
    image: partner27,
    link: 'https://www.edfenergy.com',
  },
  {
    image: partner28,
    link: 'https://www.summerenergy.com',
  },
];
